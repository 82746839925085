<template>
  <div class="outer-page">
    <div class="warrper">
      <div class="news-title">
        <h6>{{ newsList.name }}</h6>
        <p>时间：{{ newsList.createDate }} 浏览：{{ newsList.hitCount }}次</p>
      </div>
      <div class="news-article">
        <div v-html="newsList.content" class="news-content"></div>
      </div>
      <div class="next-text">
        <ul>
          <li @click="goPre" v-if="showPre">
            <a>上一篇:{{ showPre.name }}</a>
          </li>
          <li @click="goNext" v-if="showNext">
            <a>下一篇:{{ showNext.name }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "",
  data() {
    return {
      newsId: "",
      //当前新闻列表
      newsList: [],
      //当前版块所有新闻数据
      allNewsList: [],
      // 上一篇数据
      showNext: null,
      // 下一篇数据
      showPre: null,
      // 当前条数据的位置
      index: 0,
    };
  },
  computed: {
    getSubMenu() {
      return this.$store.state.permission.subMenu;
    },
    getCurrentRoute() {
      return this.$store.state.permission.currentRoute;
    },
  },
  methods: {
    getNewsId() {
      this.newsId = this.$route.params.id;
    },
    getNewsView() {
      this.$post(this.$api.NEWS_INFO.VIEW, {
        id: this.$route.query.id,
      }).then((res) => {
        this.newsList = res;
      });
    },
    //获取当前router下的数据
    getList() {
      let child = this.getSubMenu.childList.filter(
        (item) => item.router == this.getCurrentRoute
      )[0];
      if (child.Interface) {
        this.$post(child.Interface, {
          pageSize: 100,
          pageNo: 1,
        }).then((res) => {
          this.allNewsList = res.list;
          this.checkDetail();
        });
      }
    },
    checkDetail(newsItem) {
      let id;
      if (newsItem !== undefined) {
        this.index = this.allNewsList.findIndex(
          (item) => item.id == newsItem.id
        );
        id = newsItem.id;
      } else {
        // let info = this.$route.params.item;
        this.index = this.allNewsList.findIndex(
          (item) => item.id == this.$route.query.id
        );
        id = this.$route.query.id;
      }
      if (this.index !== 0) {
        this.showPre = this.allNewsList[this.index - 1];
      } else {
        this.showPre = null;
      }
      if (this.index !== this.allNewsList.length - 1) {
        this.showNext = this.allNewsList[this.index + 1];
      } else {
        this.showNext = null;
      }
      if (!id) return
      this.$post("newsview", {
        id: id,
      }).then((res) => {
        this.newsList = res;
      });
    },
    goPre() {
      this.checkDetail(this.showPre);
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
    goNext() {
      this.checkDetail(this.showNext);
      document.body.scrollTop = document.documentElement.scrollTop = 0
    },
  },
  mounted() {
    this.getNewsView();
    this.getList();
  },
  watch: {},
};
</script>

<style lang="less" scoped>
@import url("./index.less");
/deep/ .ivu-btn:hover {
  border: 1px solid #ff9c00;
  color: #ff9c00;
}
</style>